/* eslint no-bitwise: ["error", { "int32Hint": true }] */
import React from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import { Map } from 'immutable';
import { get, isEmpty } from 'lodash/fp';
import isArrayBuffer from 'lodash/isArrayBuffer';
import { put, call, select, cancelled, all, delay, race, take } from 'redux-saga/effects';
import { signOutAction, updateWorkSpaceAction, addDataLoadingAction, removeDataLoadingAction } from 'domain/env';
import { getApprovalGroupsAction } from 'domain/approvals';
import find from 'lodash/find';
import { notify } from 'lib/errorLogger';

import Api, { doLogout, logoutAction } from 'domain/api';

import { isGridWorkSpaceSelector, companiesByIdSelector } from 'domain/companies/companiesSelector';
import * as approvalSelectors from '../approvals/selectors';
import { queryToSearch, queryToSupplierUpload } from 'domain/router/helpers';
import {
  documentsWithUniqLinkedUngrouped,
  documentsAdapter,
  documentAdapter,
  documentAdapterValidated,
  documentsListAdapter,
  updateGridConfigChanges,
  getCurrentStoredConfig,
  deleteGridConfigChanges,
  decodeArrayBufferText,
  changeColumnsPositionForGridRedesign,
  requiresColumnMigration,
} from 'domain/documents/helpers';
import { navigate } from 'domain/router/redux/reduxActions';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import * as Journal from 'domain/journal/sagas';
import * as Router from 'domain/router';
import { type Jornal } from 'domain/journal/helper';
import { JEAdapter, messagesAdapter } from 'domain/journal/adapters';
import * as JEactions from 'domain/journal/actions';
import { jeInvoiceTypeSelector } from 'domain/journal/selectors';
import * as Categories from 'domain/categories/sagas';
import download from 'lib/download';
import {
  yieldSuccessNotification,
  yieldErrorNotification,
  yieldInfoNotification,
  yieldWarningNotification,
} from 'lib/toasts';
import { FormattedMessage } from 'react-intl';
import * as actions from './documentsActions';
import * as selector from './documentSelector';
import { userGUIDSelector, userIdSelector, backSearchUrlSelector } from 'domain/env/envSelector';
import { rootCategoriesListSelector } from 'domain/categories';
import { isCategoryAll, findCatById, getNestedCategory } from 'domain/categories/helpers';
import { DocumentsFactory } from './documentsModel';

import { executeFunc } from 'lib/helpers';
import { ToastEventHandler } from 'components/Tables/toast';
import * as ACL from 'domain/restriction';
import * as adapters from './adapters';

import indexedDb from 'indexedDb';
import CONST, { WS_GRID_RECENT_DEFAULT_NAME, SEARCH_DEFAULT_MAX_RESULT } from './constants';
import tokenKeeper from 'lib/apiTokenKeeper';
import { PublishTypes } from 'pages/document/components/DocumentPublishButton/DropdownButton/config';
import { ensureGetExtractedTableData } from '../textract/sagas';
import { storage } from 'lib/storage';

export { selector, actions };

// eslint-disable-next-line import/no-cycle
export * from './sagas.navigation';

export function* ensureDocumentList() {
  yield put({ type: actions.documentFetchList.request });
  try {
    const { data } = yield call(Api.getListRecent, { params: {} });
    yield put({
      type: actions.documentFetchList.success,
      payload: documentsAdapter({ ...data }),
    });
  } catch (err) {
    yield doLogout(actions.documentFetchList, err);
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.documentFetchList.failure,
        err: 'cancelled',
      });
    }
  }
}

export function* ensureDocumentsExportFormats() {
  yield put({ type: actions.documentGetExportFormats.request });
  try {
    const { data } = yield call(Api.getDocumentsExportFormats, {
      params: {},
    });
    yield put({
      type: actions.documentGetExportFormats.success,
      payload: new Map(data),
    });
  } catch (err) {
    yield doLogout(actions.documentGetExportFormats, err);
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.documentGetExportFormats.failure,
        err: 'cancelled',
      });
    }
  }
}

type Params = {|
  force: boolean,
  params: {|
    category: number,
    companyId: string,
    companyType?: string,
    pageToken: string,
    query: string,
  |},
  limit?: number,
  action: any,
  infiniteScroll: boolean,
|};

// eslint-disable-next-line max-len
export function* ensureSearchDocuments(
  { params, force, infiniteScroll }: Params,
  maxResults = SEARCH_DEFAULT_MAX_RESULT,
  manual = true,
) {
  // query can be passed as param  in case of document next/previous
  // navigation. The reason is that we dont have query in router selector
  // on document page
  const query = params.query ? params.query : yield select(Router.querySelector);

  const { companyType, companyId } = params;
  const workspace = companyType ? { companyType } : {};
  const { category } = params;
  if (manual) {
    yield put({ type: actions.documentSearchAction.request });
  }
  try {
    let count = 0;
    let { pageToken } = params;
    let adoptedData;
    let collectedData = DocumentsFactory();
    do {
      const { data } = yield call(Api.documentSearchByCategory, {
        params: {
          maxResults: maxResults || SEARCH_DEFAULT_MAX_RESULT,
          category,
          companyId,
          pageToken,
          ...query,
          isConfidential: workspace && workspace.companyType === 'confidential',
        },
        paramsSerializer: (d) => queryToSearch(d),
      });
      // eslint-disable-next-line prefer-destructuring
      pageToken = data.pageToken;
      adoptedData = documentsAdapter({ ...data });
      collectedData = collectedData
        // eslint-disable-next-line no-loop-func
        .update('list', (l) => l.merge(adoptedData.list))
        .set('pageToken', adoptedData.pageToken)
        .set('count', adoptedData.count)
        .set('company_total', adoptedData.company_total)
        .set('processing', adoptedData.processing)
        .set('count_without_link_panels', adoptedData.count_without_link_panels)
        .set('searchQuery', params.searchQuery);
      count = documentsWithUniqLinkedUngrouped(collectedData.list.toList()).size;
    } while (count < 50 && pageToken != null);
    const type = force ? actions.documentForceSearch.success : actions.documentSearchAction.success;
    yield put({
      type,
      payload: { data: collectedData, infiniteScroll },
    });
  } catch (err) {
    yield doLogout(actions.documentSearchAction, err);
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.documentSearchAction.failure,
        error: 'canceled',
      });
    }
  }
}

// eslint-disable-next-line max-len
export function* ensureSupplierSearchDocuments(
  { params, infiniteScroll, limit, action = actions.supplierDocumentSearchAction }: Params,
  maxResults = limit || SEARCH_DEFAULT_MAX_RESULT,
  manual = true,
) {
  // query can be passed as param  in case of document next/previous
  // navigation. The reason is that we dont have query in router selector
  // on document page
  const query = params.query ? params.query : yield select(Router.querySelector);

  const { companyId, stateColumn } = params;

  if (manual) {
    yield put({ type: actions.supplierDocumentSearchAction.request });
  }
  try {
    let count = 0;
    let countWithoutLinkPanels = 0;
    let { pageToken } = params;
    let adoptedData;
    let collectedData = DocumentsFactory();
    do {
      const { data } = yield call(Api.documentSearchBySupplier, {
        params: {
          maxResults: maxResults || SEARCH_DEFAULT_MAX_RESULT,
          companyId,
          pageToken,
          state_column: stateColumn,
          ...query,
        },
        paramsSerializer: (d) => queryToSearch(d),
      });

      // eslint-disable-next-line prefer-destructuring
      pageToken = data.pageToken;
      count = data[`${stateColumn}_count`];
      countWithoutLinkPanels = data[`${stateColumn}_count_without_link_panels`];
      adoptedData = documentsAdapter({ ...data });
      collectedData = collectedData
        // eslint-disable-next-line no-loop-func
        .update('list', (l) => l.merge(adoptedData.list))
        .set('stateColumn', stateColumn)
        .set('pageToken', pageToken)
        .set('count', count)
        .set('company_total', adoptedData.company_total)
        .set('processing', adoptedData.processing)
        .set('count_without_link_panels', countWithoutLinkPanels)
        .set('searchQuery', params.searchQuery);
      count = documentsWithUniqLinkedUngrouped(collectedData.list.toList()).size;
    } while (count < 50 && pageToken != null);
    yield put({
      type: action.success,
      payload: { data: collectedData, infiniteScroll, stateColumn, pageToken },
    });
  } catch (err) {
    yield doLogout(actions.supplierDocumentSearchAction, err);
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.supplierDocumentSearchAction.failure,
        error: 'canceled',
      });
    }
  }
}

export function* duplicateDocument({ payload: { id, resolve, reject } }) {
  try {
    yield call(Api.duplicateDocument, {
      data: {
        documentID: id,
      },
    });
    yield put({
      type: actions.duplicateDocumentAction.success,
      // payload: adapters.documentAdapter(data),
    });
    yieldSuccessNotification(
      <FormattedMessage id="document.actions.duplicate" defaultMessage="Document successfully duplicated" />,
    );
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.duplicateDocumentAction, err);
  }
}

export function* ensureGetDocument(documentID, companyId, isLoader = true) {
  if (isLoader) {
    yield put({
      type: actions.documentGet.request,
    });
  }

  try {
    const { data } = yield call(Api.getDocument, {
      params: { documentID },
    });
    yield put({
      type: actions.documentGet.success,
      payload: documentAdapterValidated(data),
    });
  } catch (err) {
    // eslint-disable-next-line max-len
    if (
      err &&
      err.response &&
      err.response.status &&
      err.response.status.toString() === process.env.REACT_APP_NOT_FOUND_CODE
    ) {
      yield put(navigate.push(generatePath(ROUTES_PATH.DOCUMENT_NOT_FOUND.absolute, { companyId })));
    }
    yield doLogout(actions.documentGet, err);
  }
}

export function* ensureGetDocumentLinkedCount({ linkedID, resolve, reject }) {
  try {
    const { data } = yield call(Api.getDocumentLinkedCount, {
      params: {
        linkedID,
      },
    });
    if (typeof resolve === 'function') resolve(data);
  } catch (err) {
    yield doLogout(actions.documentGetLinkedCountAction, err);
    if (typeof resolve === 'function') reject();
  }
}

export function* ensureGetUnreadRequestsAction() {
  yield put({
    type: actions.getUnreadRequestsAction.request,
  });

  try {
    const { data } = yield call(Api.getUnreadRequests, {
      params: {},
    });
    yield put({
      type: actions.getUnreadRequestsAction.success,
      payload: adapters.unredRequestDocsAdapter(data),
    });
  } catch (err) {
    yield put({
      type: actions.getUnreadRequestsAction.failure,
    });
  }
}

export function* ensureUpdateDocumentTags(payload) {
  const { documentID, tagsToAdd, tagsToRemove, resolve, reject } = payload;
  try {
    const { data } = yield call(Api.updateDocumentTags, {
      data: {
        documentID,
        tagsToAdd: tagsToAdd.join(','),
        tagsToRemove: tagsToRemove.join(','),
      },
    });
    yield put({
      type: actions.documentUpdate.success,
      payload: documentAdapter(data),
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject();
    yield doLogout(actions.documentUpdateTags, err);
  }
}

export function* ensureUpdateMultipleDocumentsTags({ payload }) {
  const { documentID, tagsToAdd, tagsToRemove, resolve, reject } = payload;
  try {
    const { data } = yield call(Api.updateMultipleDocumentsTags, {
      data: {
        documentID,
        tagsToAdd: tagsToAdd.join(','),
        tagsToRemove: tagsToRemove.join(','),
      },
    });
    yield put({
      type: actions.documentMultipleUpdateTags.success,
      payload: documentsListAdapter(data.documents),
    });
    if (typeof resolve === 'function') {
      resolve({ success: data.success.length, total: documentID.size });
    }
  } catch (err) {
    if (typeof reject === 'function') reject();
    yield doLogout(actions.documentMultipleUpdateTags, err);
  }
}

export function* ensureUpdateDocumentTagsInList(payload): void {
  const { documentID, tagsToAdd, tagsToRemove, resolve, reject } = payload;
  try {
    const { data } = yield call(Api.updateDocumentTags, {
      data: {
        documentID,
        tagsToAdd: tagsToAdd.join(','),
        tagsToRemove: tagsToRemove.join(','),
      },
    });
    yield put({
      type: actions.documentUpdateTagsInList.success,
      payload: documentAdapter(data),
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject();
    yield doLogout(actions.documentUpdateTags, err);
  }
}

export function* ensureLinkDocumentInList(payload) {
  const { documentID, linkID, text, params, resolve = (x) => x, reject = (x) => x } = payload;
  const documentIds = Array.isArray(documentID) ? documentID : [documentID];
  const existingLinkID = yield select(selector.documentLinkedTagSelector);
  // eslint-disable-next-line no-unneeded-ternary
  const linkTag = linkID || existingLinkID;
  try {
    const { data } = yield call(Api.linkDocuments, {
      data: {
        documentID: documentIds,
        linkID: linkTag,
        text,
      },
    });

    const list = yield select(selector.documentsByIdSelector);
    // request for all the documents that are currently in a list after unlinkin
    yield call(ensureSearchDocuments, { params, force: true }, list.size);

    const { tag } = yield select(selector.linkedSelector);

    if (tag === linkTag) {
      yield put({
        type: actions.documentGetLinkedAction.type,
        payload: {
          tag: linkTag,
        },
      });
    }

    resolve(data);
  } catch (err) {
    reject(err);
    yield doLogout(actions.documentLinkInListAction, err);
  }
}

export function* ensureLockLinkedDocs(payload) {
  const { linkID, locked, resolve, reject } = payload;

  try {
    const { data } = yield call(Api.lockLinkedDocuments, {
      data: { linkID, locked },
    });

    const newLinkId = data.items[0].linkid;

    yield put({
      type: actions.lockLinkedDocsAction.success,
      payload: {
        list: documentsAdapter({ ...data }).list,
        tag: newLinkId,
      },
    });

    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject();
    yield doLogout(actions.lockLinkedDocsAction, err);
  }
}

export function* ensureGetGridViewDataOnlyGridView(params: Params) {
  if (params) {
    const isGrid = yield select(isGridWorkSpaceSelector);
    if (isGrid) {
      yield call(ensureGetGridViewData, { params });
    }
  }
}

export function* ensureUnlinkDocumentInList(payload) {
  const { documentID, params, resolve = (x) => x, reject = (x) => x } = payload;
  const documentIds = Array.isArray(documentID) ? documentID : [documentID];

  try {
    const { data } = yield call(Api.unlinkMultiDocuments, {
      data: {
        documentID: documentIds,
      },
    });

    const isGrid = yield select(isGridWorkSpaceSelector);

    if (isGrid) {
      yield call(ensureGetGridViewDataOnlyGridView, params);
    } else {
      const list = yield select(selector.documentsByIdSelector);
      // request for all the documents that are currently in a list after unlinkin
      yield call(ensureSearchDocuments, { params, force: true }, list.size);
    }

    resolve(data);
  } catch (err) {
    reject();
    yield doLogout(actions.documentLinkInListAction, err);
  }
}

export function* ensureUpdateLink(payload) {
  const { linkID, text, resolve, reject } = payload;
  try {
    const { data } = yield call(Api.updateLink, {
      data: {
        linkID,
        text,
      },
    });

    // @to-do process event
    yield put({
      type: actions.documentUpdateLinkAction.success,
      payload: documentsAdapter({ ...data }),
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject();
    yield doLogout(actions.documentLinkInListAction, err);
  }
}

export function* ensureAcceptDocument(
  documentID,
  { payload: { resolve, reject, force, remainOnDocument, userConfirmed, publishType } },
) {
  yield put({ type: actions.documentAccept.request });
  try {
    const { data } = yield call(Api.acceptDocument, {
      params: {
        documentID,
        force: force | 0, // bitwise type casting
        userConfirmed,
      },
    });

    yield put({
      type: actions.documentsJustPublishedAction.type,
      payload: publishType,
    });

    // if remainOnDocument=true we update JE
    if (remainOnDocument) yield call(Journal.ensureJounalEntry, documentID);
    yield put({
      type: actions.documentUpdate.success,
      payload: documentAdapter(data),
    });

    // after successful accept, we dont update JE from accept API response
    // instead we will redirect to workspace or next document
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject();
    if (err.response && err.response.status.toString() === '422') {
      // this is a bit redundant now in favor of JE update below, but must be checked
      // before it can be safe-deleted
      yield put({
        type: actions.documentAccept.success,
        payload: documentAdapterValidated(err.response.data),
      });

      // if unsuccessful accept, JE must be updated from accept api response
      // to bring errors/warn/info
      const { details }: { details: Jornal } = err.response.data;
      ToastEventHandler.onResponce(messagesAdapter(details.messages));
      yield put({
        type: JEactions.updateJournalEntryAction.success,
        payload: JEAdapter(details),
      });
    }
    // this checks for network error (lost connection for instance)
    // in more or less crossbrowser manner
    if (typeof err.response === 'undefined') {
      ToastEventHandler.onResponceTranslate({
        id: 'document.journalEntry.toast.publish_network_error',
        defaultMessage:
          'We experienced network error while document was being published. Please refresh the page and try again if document remains unpublished',
        type: 'error',
      });
    }
    yield doLogout(actions.documentAccept, err);
  }
}

export function* ensureDocumentScheduleAccept(
  documentsIds,
  { payload: { resolve, reject, userConfirmed, remainOnDocument = false } },
) {
  // if remainOnDocument=true we update JE
  const isNeedUpdateJE = remainOnDocument && documentsIds.length === 1;
  try {
    if (isNeedUpdateJE) {
      yield put({
        type: addDataLoadingAction.type,
      });
    }
    yield call(Api.bulkAcceptDocuments, {
      data: {
        documentID: documentsIds,
        userConfirmed,
      },
    });
    if (isNeedUpdateJE) {
      yield call(Journal.ensureJounalEntry, documentsIds[0]);
      yield put({
        type: actions.documentsJustPublishedAction.type,
        payload: PublishTypes.SchedulePublish,
      });
      yield put({
        type: removeDataLoadingAction.type,
      });
    }
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (isNeedUpdateJE) {
      yield put({
        type: removeDataLoadingAction.type,
      });
    }
    if (typeof reject === 'function') reject();
    yield doLogout(actions.documentScheduleAcceptAction, err);
  }
}

export function* ensureRejectDocument(documentID, reason, { resolve, reject }) {
  try {
    const { data } = yield call(Api.rejectDocument, {
      data: {
        documentID,
        reason,
      },
    });
    yield put({
      type: actions.documentUpdate.success,
      payload: documentAdapter(data),
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject();
    yield doLogout(actions.documentReject, err);
  }
}

export function* ensureRemoveDocument({ documentID, resolve, reject }) {
  yield put({
    type: actions.documentRemove.request,
    payload: documentID,
  });
  try {
    const { data } = yield call(Api.removeDocument, {
      data: { documentID },
    });
    yield put({
      type: actions.documentRemove.success,
      payload: {
        id: documentID,
        data,
      },
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    const error = err?.response?.data;
    if (error) yieldErrorNotification(error);
    yield doLogout(actions.documentRemove, err);
    if (typeof reject === 'function') reject(err);
  }
}

export function* ensureRemoveDocumentNotes({ documentID, resolve, reject }) {
  try {
    const { data } = yield call(Api.removeNotes, {
      data: { documentID },
    });
    yield put({
      type: actions.documentUpdate.success,
      payload: documentAdapter(data),
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    const error = err?.response?.data?.message;
    error
      ? yieldErrorNotification(error)
      : yieldErrorNotification(<FormattedMessage id="app.generalUnknownError" defaultMessage="Something went wrong" />);
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.documentUpdate, err);
  }
}

export function* ensureRemoveDocumentNotesInList({ documentID, resolve, reject }) {
  try {
    const { data } = yield call(Api.removeNotes, {
      data: { documentID },
    });
    yield put({
      type: actions.documentRemoveNotesInList.success,
      payload: documentAdapter(data),
    });
    resolve();
  } catch (err) {
    const error = err?.response?.data?.message;
    error
      ? yieldErrorNotification(error)
      : yieldErrorNotification(<FormattedMessage id="app.generalUnknownError" defaultMessage="Something went wrong" />);
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.documentRemoveNotesInList, err);
  }
}

export function* ensureReadDocument(routeDocumentID, componentDocumentID) {
  // Event might be dispatched on pdf load event when
  // user has already left this document page and route already has another document id.
  // In this case we are not performing update of componentDocumentID as user
  // left its page before render completed.
  // The issue was that event was dispatched for componentDocumentID and we updated document
  // with routeDocumentID
  if (routeDocumentID === componentDocumentID) {
    try {
      const { data } = yield call(Api.updateDocumentTags, {
        data: {
          documentID: routeDocumentID,
          tagsToAdd: '_S_READ',
          tagsToRemove: '_S_NEW',
        },
      });
      yield put({
        type: actions.documentUpdate.success,
        payload: documentAdapter(data),
      });
    } catch (err) {
      yield doLogout(actions.documentUpdateTags, err);
    }
  }
}

export function* ensureUpdateDocumentNotesShow({ documentID, notes, notesColor, resolve, reject }) {
  try {
    const { data } = yield call(Api.updateNotes, {
      data: {
        documentID,
        notes,
        notes_color: notesColor,
      },
    });
    yield put({
      type: actions.documentUpdate.success,
      payload: documentAdapter(data),
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    const error = err?.response?.data?.message;
    error
      ? yieldErrorNotification(error)
      : yieldErrorNotification(<FormattedMessage id="app.generalUnknownError" defaultMessage="Something went wrong" />);
    yield doLogout(actions.documentUpdateTags, err);
    if (typeof reject === 'function') reject(err);
  }
}

export function* ensureSetDocumentAsCover(payload) {
  const { documentId: documentID, resolve, reject } = payload;
  try {
    const { data } = yield call(Api.setDocumentAsCover, {
      data: {
        documentID,
      },
    });
    yield put({
      type: actions.documentSetAsCoverAction.success,
      payload: { ...documentAdapter(data), documentID },
    });

    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.documentSetAsCoverAction, err);
  }
}

export function* ensureGetTagsSuggestions(prefix, resolve, reject) {
  try {
    const { data } = yield call(Api.getTagSuggestion, {
      params: {
        prefix,
      },
    });
    resolve(data);
  } catch (err) {
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.documentUpdateTags, err);
  }
}

export function* ensureGetMRUTags() {
  const companyId = yield select(selector.currentCompanySelector);

  try {
    const { data } = yield call(Api.getMRUTags, {
      params: {},
    });
    yield put({
      type: actions.documentGetMRUTags.success,
      payload: data,
      companyId,
    });
  } catch (err) {
    yield doLogout(actions.documentGetMRUTags, err);
  }
}

export function* ensureUnreadDocument({ documentID, companyId, resolve, reject }) {
  try {
    yield call(Api.updateDocumentTags, {
      data: {
        documentID,
        tagsToAdd: '_S_NEW',
        tagsToRemove: '_S_READ',
      },
    });
    if (typeof resolve === 'function') resolve();

    yield put(navigate.push(generatePath(ROUTES_PATH.COMPANY_WORKSPACE.absolute, { companyId })));
  } catch (err) {
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.documentUpdateTags, err);
  }
}

export function* ensureUnreadDocumentInList({ documentID, resolve, reject }) {
  const query = yield select(Router.querySelector);
  try {
    const { data } = yield call(Api.updateDocumentTags, {
      data: {
        documentID,
        tagsToAdd: '_S_NEW',
        tagsToRemove: '_S_READ',
      },
    });
    let type = actions.documentUpdateTagsInList.success;
    if (query && query.tags && query.tags.includes('read') && !query.tags.includes('new')) {
      type = actions.documentUnreadInFilteredList.success;
    }
    yield put({
      type,
      payload: documentAdapter(data),
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.documentUpdateTags, err);
  }
}

export function* ensureReadDocumentInList({ documentID, resolve, reject }) {
  const query = yield select(Router.querySelector);
  try {
    const { data } = yield call(Api.updateDocumentTags, {
      data: {
        documentID,
        tagsToAdd: '_S_READ',
        tagsToRemove: '_S_NEW',
      },
    });
    let type = actions.documentUpdateTagsInList.success;
    if (query && query.tags && query.tags.includes('new') && !query.tags.includes('read')) {
      type = actions.documentUnreadInFilteredList.success;
    }
    yield put({
      type,
      payload: documentAdapter(data),
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.documentUpdateTags, err);
  }
}

export function* ensureWorkerSearchDocuments(matchParams) {
  function* prepare(params) {
    const { category } = params;

    const list = yield select(selector.documentsByIdSelector);
    const query: string = yield select(Router.querySelector);
    const { companyType, companyId } = params;
    const workspace = companyType ? { companyType } : {};
    return {
      maxResults: list.count() < 30 ? 30 : list.count(),
      category,
      companyId,
      ...query,
      isConfidential: workspace && workspace.companyType === 'confidential',
    };
  }

  return {
    // apiName: 'documentSearch',
    apiName: 'documentSearchByCategory',
    config: {
      params: yield call(prepare, matchParams),
    },
    prepare,
    success(data) {
      if (!data?.count && matchParams.category !== matchParams.category1) {
        const route =
          matchParams?.companyType === 'confidential'
            ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
            : ROUTES_PATH.COMPANY_WORKSPACE.absolute;
        return navigate.push(generatePath(route, { ...matchParams }));
      }
      return {
        type: actions.documentWorkerSearch.success,
        payload: documentsAdapter(data),
      };
    },
    failure(err) {
      return logoutAction(actions.documentSearchAction, err);
    },
  };
}

export function* ensureWorkerSearchGridDocuments(matchParams) {
  // eslint-disable-next-line require-yield
  function* prepare() {
    return {};
  }

  return {
    apiName: 'documentLatestCompanyTimestamp',
    config: {
      params: yield call(prepare, matchParams),
    },
    prepare,
    success(data) {
      const { timeStamp } = data;

      return {
        type: actions.documentWorkerCompanyLatestTimestamp.success,
        payload: timeStamp,
      };
    },
    failure(err) {
      return logoutAction(actions.documentWorkerCompanyLatestTimestamp, err);
    },
  };
}

export function* ensureDirectSearchDocuments({ params, force, infiniteScroll }, maxResults = 30, manual = true) {
  if (manual) {
    yield put({ type: actions.documentSearchAction.request });
  }
  try {
    let count = 0;
    let { pageToken } = params;
    let adoptedData;
    let collectedData = DocumentsFactory();
    do {
      const { data } = yield call(Api.documentSearch, {
        params: {
          maxResults,
          ...params,
          pageToken,
        },
        paramsSerializer: (d) => queryToSearch(d),
      });

      // eslint-disable-next-line prefer-destructuring
      pageToken = data.pageToken;
      adoptedData = documentsAdapter({ ...data });
      collectedData = collectedData
        // eslint-disable-next-line no-loop-func
        .update('list', (l) => l.merge(adoptedData.list))
        .set('pageToken', adoptedData.pageToken)
        .set('count', adoptedData.count)
        .set('company_total', adoptedData.company_total)
        .set('processing', adoptedData.processing)
        .set('count_without_link_panels', adoptedData.count_without_link_panels)
        .set('searchQuery', params.searchQuery);
      count = documentsWithUniqLinkedUngrouped(collectedData.list.toList()).size;
    } while (count < 50 && pageToken != null);

    const type = force ? actions.documentForceSearch.success : actions.documentSearchAction.success;
    yield put({
      type,
      payload: { data: collectedData, infiniteScroll },
    });
  } catch (err) {
    yield doLogout(actions.documentSearchAction, err);
  }
}

export function* ensureWorkerTransactionErp(documentID) {
  // eslint-disable-next-line require-yield
  function* prepare() {
    return {
      documentID,
    };
  }
  function* checkDisabled() {
    const s = yield select(selector.erpDuplicatedSelector);
    const isGranted = yield select(ACL.isGranted);
    if (!isGranted(ACL.CAN_CHECK_DUPLICATE_ERP_DOCS)) return true;
    return s.has(documentID);
  }
  return {
    apiName: 'getTransactionErp',
    prepare,
    interval: process.env.REACT_APP_ERP_TRANSACTION_POLLING_INTERVAL || 30000,
    config: {
      params: yield call(prepare),
    },
    disabled: yield call(checkDisabled),
    success(data) {
      if (data !== null) {
        // this API return just toast about duplication
        const message = messagesAdapter(data.messages).get(0);
        ToastEventHandler.onResponseForDuplicate(message);
        return {
          type: actions.documentWorkerTransactionErp.success,
          payload: { documentID },
        };
      }
      return {
        type: actions.documentWorkerTransactionErp.request,
      };
    },
    failure(err) {
      return logoutAction(actions.documentWorkerTransactionErp, err);
    },
  };
}

export function* ensureUnlinkDocuments(payload) {
  const { linkid, params, resolve } = payload;
  try {
    yield call(Api.unlinkDocuments, {
      data: {
        linkID: linkid,
      },
    });
    const list = yield select(selector.documentsByIdSelector);
    // request for all the documents that are currently in a list after unlinkin
    yield call(ensureSearchDocuments, { params, force: true }, list.size);
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    yield doLogout(actions.documentsUnlink, err);
  }
}

export function* ensureGetLinkedDocuments({ tag, pageToken, resolve, reject, isOpen = true }) {
  const linked = yield select(selector.linkedSelector);
  yield put({ type: actions.documentGetLinkedAction.request });
  try {
    const { data } = yield call(Api.documentSearch, {
      params: {
        maxResults: 20,
        searchQuery: `(linkid: ${tag})`,
        pageToken,
      },
      paramsSerializer: (d) => queryToSearch(d),
    });

    yield put({
      type: actions.documentGetLinkedAction.success,
      payload: {
        list:
          linked.tag === tag && pageToken
            ? linked.list.merge(documentsListAdapter(data.items))
            : documentsListAdapter(data.items),
        tag,
        isOpen,
        count: data.count,
        pageToken: data.pageToken,
      },
    });

    if (typeof resolve === 'function') resolve(data);
  } catch (err) {
    if (typeof reject === 'function') reject();
    yield put({ type: actions.documentGetLinkedAction.failure, err });
  }
}

export function* ensureGetLinkedDocumentsForPreview({
  tag,
  pageToken,
  resolve,
  reject,
  isOpen = true,
  searchedDocumentId,
}) {
  const linked = yield select(selector.linkedSelector);
  yield put({ type: actions.documentGetLinkedForPreviewAction.request });
  try {
    let currentPageToken = pageToken || '';
    let count = '';
    let isNotFoundDocument = false;
    const items = [];
    do {
      const { data } = yield call(Api.documentSearch, {
        params: {
          maxResults: 20,
          searchQuery: `(linkid: ${tag})`,
          pageToken: currentPageToken,
        },
        paramsSerializer: (d) => queryToSearch(d),
      });
      currentPageToken = data.pageToken;
      items.push(...data.items);
      count = data.count;
      isNotFoundDocument = data.items.findIndex(({ documentID }) => documentID === searchedDocumentId) === -1;
    } while (currentPageToken !== null && searchedDocumentId !== null && isNotFoundDocument);

    yield put({
      type: actions.documentGetLinkedForPreviewAction.success,
      payload: {
        list:
          linked.tag === tag && currentPageToken
            ? linked.list.merge(documentsListAdapter(items))
            : documentsListAdapter(items),
        tag,
        isOpen,
        count,
        pageToken: currentPageToken,
      },
    });

    if (typeof resolve === 'function') resolve(items);
  } catch (err) {
    if (typeof reject === 'function') reject();
    yield put({ type: actions.documentGetLinkedForPreviewAction.failure, err });
  }
}

// eslint-disable-next-line consistent-return
export function* ensureGetLinkedDocumentsIDs({ linkid, resolve, reject }) {
  try {
    let pageToken = '';
    const res = [];
    do {
      const { data } = yield call(Api.documentSearch, {
        params: {
          maxResults: 1000,
          searchQuery: `(linkid: ${linkid})`,
          pageToken,
        },
        paramsSerializer: (d) => queryToSearch(d),
      });
      pageToken = data.pageToken;
      res.push(...data.items.filter(({ protected: isProtected }) => !isProtected).map(({ documentID }) => documentID));
    } while (pageToken != null);
    if (typeof resolve === 'function') resolve(res);
    return res;
  } catch (err) {
    if (typeof reject === 'function') reject();
  }
}

export function* ensureMarkAsPaid({ payload: { documentId, isPaid, needJESync = true } }) {
  const requestData = {
    documentID: documentId,
    document_type: isPaid ? 'paid' : 'unpaid',
  };
  const isGranted = yield select(ACL.isGranted);

  try {
    const { data } = yield call(Api.changeTypeDocument, { data: requestData });
    yield put({
      type: actions.markAsPaidAction.success,
      payload: documentAdapterValidated(data),
    });

    // TODO: DA-7033 - check and remove request for getJournalEntry (maybe legacy sync with reindexCache)
    if (needJESync && isGranted(ACL.IS_WORK_ON_WITH_CURRENT_DOCUMENT_AS_FINANCIAL)) {
      yield call(Journal.ensureSyncJE, documentId);
    }

    const [id, msg] = isPaid ? ['paid', 'Paid'] : ['unpaid', 'Unpaid'];
    yieldSuccessNotification(
      <FormattedMessage id={`documents.${id}.toast`} defaultMessage={`Document marked as ${msg}`} />,
    );
  } catch (err) {
    const error = err?.response?.data?.message;
    error
      ? yieldErrorNotification(error)
      : yieldErrorNotification(<FormattedMessage id="app.generalUnknownError" defaultMessage="Something went wrong" />);
    yield doLogout(actions.markAsPaidAction, err);
  }
}

export function* ensureUpdateDocumentNotes({ payload: { notes, notesColor, documentID, cb } }) {
  try {
    const { data } = yield call(Api.updateNotes, {
      data: {
        notes,
        documentID,
        notes_color: notesColor,
      },
    });
    yield put({
      type: actions.documentUpdateNotes.success,
      payload: data,
    });
    if (cb && typeof cb.resolve === 'function') cb.resolve();
  } catch (err) {
    const error = err?.response?.data?.message;
    error
      ? yieldErrorNotification(error)
      : yieldErrorNotification(<FormattedMessage id="app.generalUnknownError" defaultMessage="Something went wrong" />);
    yield doLogout(actions.documentUpdateNotes, err);
    if (cb && typeof cb.reject === 'function') cb.reject();
  }
}

export function* ensureUploadDocument({ payload, ...config }) {
  const formDate = new global.FormData();
  formDate.append('file', payload.file);
  const params = {
    ...payload.params.toJS(),
  };
  if (payload.isConfidential) {
    Object.defineProperty(params, 'isConfidential', {
      enumerable: true,
      configurable: true,
      value: 1,
    });
  }
  try {
    const { data } = yield call(Api.uploadDocument, {
      data: formDate,
      params,
      ...config,
    });
    yield put({
      type: actions.documentUploadAction.success,
      payload: data,
      id: payload.id,
    });
    if (typeof payload.cb === 'function') payload.cb();
    yield delay(5000);
    yield put(actions.queueRemoveAction([payload.id]));
  } catch (err) {
    if (axios.isCancel(err)) {
      yield put(actions.queueRemoveAction([payload.id]));
    } else if (err.response && (err.response.status || '').toString() === process.env.REACT_APP_UNAUTHORIZED_CODE) {
      yield put({
        type: signOutAction.type,
      });
    } else {
      yield put({
        type: actions.documentUploadAction.failure,
        err,
        payload,
      });
    }
  }
}

export function* ensureSupplierUploadDocument({ payload, ...config }) {
  const formDate = new global.FormData();
  formDate.append('file', payload.file);
  const params = {
    ...payload.params.toJS(),
  };
  try {
    const { data } = yield call(Api.uploadSupplierDocument, {
      data: formDate,
      params,
      ...config,
      paramsSerializer: (d) => queryToSupplierUpload(d),
    });
    yield put({
      type: actions.supplierDocumentUploadAction.success,
      payload: data,
      id: payload.id,
    });
    if (typeof payload.cb === 'function') payload.cb();
    yield delay(5000);
    yield put(actions.queueRemoveAction([payload.id]));
  } catch (err) {
    if (axios.isCancel(err)) {
      yield put(actions.queueRemoveAction([payload.id]));
    } else if (err.response && (err.response.status || '').toString() === process.env.REACT_APP_UNAUTHORIZED_CODE) {
      yield put({
        type: signOutAction.type,
      });
    } else {
      yield put({
        type: actions.supplierDocumentUploadAction.failure,
        err,
        payload,
      });
    }
  }
}

export function* ensureSplitDocument({ newdocs, documentID, splitOptions, resolve }) {
  try {
    yield call(Api.splitDocument, {
      data: {
        splitOptions,
        newdocs,
        documentID,
      },
    });

    const isGrid = yield select(isGridWorkSpaceSelector);
    yield put({
      type: actions.documentSplit.success,
      payload: {
        documentID,
        isGrid,
      },
    });
    if (typeof resolve === 'function') resolve();
  } catch (err) {
    yield doLogout(actions.documentSplit, err);
  }
}

export function* ensureSignDocument({ signatureID, documentID }) {
  try {
    yield call(Api.signDocument, {
      data: {
        signatureID,
        documentID,
      },
    });
    const documents = yield select(selector.documentsByIdSelector);
    let doc = documents.get(documentID);
    if (!doc) {
      const linkedDocs = yield select(selector.documentLinkedSelector);
      doc = linkedDocs.get(documentID);
    }
    const newDocument = doc.update('tags', (tags) => tags.add(`_S_SIG_DOKKA${signatureID}_PENDING`));
    yield put({
      type: actions.documentSignDocument.success,
      payload: {
        ...documentAdapter(newDocument.toJS()),
        documentID,
      },
    });
  } catch (err) {
    const errorMessage = err?.response?.data?.message;

    if (errorMessage) {
      yieldWarningNotification(errorMessage);
    }
    yield doLogout(actions.documentSignDocument, err);
  }
}

export function* ensureSignShowDocument({ signatureID, documentID }) {
  try {
    yield call(Api.signDocument, {
      data: {
        signatureID,
        documentID,
      },
    });
    const document = yield select(selector.documentSelector);
    const newDocument = document.update('tags', (tags) => tags.add(`_S_SIG_DOKKA${signatureID}_PENDING`));
    yield put({
      type: actions.documentSignShowDocument.success,
      payload: {
        ...documentAdapter(newDocument.toJS()),
      },
    });
  } catch (err) {
    const errorMessage = err?.response?.data?.message;

    if (errorMessage) {
      yieldWarningNotification(errorMessage);
    }

    yield doLogout(actions.documentSignShowDocument, err);
  }
}

export function ensureExportData(params) {
  return function* exportDataGen({ payload }) {
    const isGrid = yield select(isGridWorkSpaceSelector);

    try {
      if (isGrid) {
        executeFunc(payload.actionForGrid[payload.format]);
      } else {
        const category = getNestedCategory(params);
        const isConfidential = params.companyType === 'confidential';
        const { format, linkedId } = payload;
        const dokkaToken = yield call(tokenKeeper.getToken);
        const companyId = yield call(tokenKeeper.getCompany);
        const language = yield call(tokenKeeper.getLanguage);
        const baseParams = { dokkaToken, companyId, language, outputFormat: format, isConfidential };
        const queryParams = linkedId
          ? // for link panel we send linkId only
            {
              ...baseParams,
              linkPanelID: linkedId,
            }
          : {
              // for workspace we send category and filters (as query)
              // to export filtered documents only
              ...baseParams,
              ...(yield select(Router.querySelector)),
              category,
            };

        download(Api.exportData(queryParams));
      }
    } catch (err) {
      yield doLogout(actions.nextDocumentAction, err);
    }
  };
}

function* actualizeLinkedDocuments(documents) {
  const linkedDocuments = yield select(selector.documentLinkedSelector);
  const [...linkedDocumentsIds] = linkedDocuments.keys();
  const updatedLinkedDocuments = documents.filter(({ documentID }) => linkedDocumentsIds.includes(documentID));

  const documentsForMerge = documentsAdapter({
    items: updatedLinkedDocuments,
  }).list;

  if (updatedLinkedDocuments.length) {
    yield put({
      type: actions.documentsBulkUpdateAction.success,
      payload: { linked: linkedDocuments.merge(documentsForMerge) },
    });
  }
}

export function* actualizeDocuments(documents) {
  const documentsList = yield select(selector.documentsByIdSelector);
  const documentsForMerge = documentsAdapter({ items: documents }).list;
  yield put({
    type: actions.documentsBulkUpdateAction.success,
    payload: {
      documents: documentsList.mergeWith(
        (oldVal, newVal) => newVal.set('gridMeta', oldVal.gridMeta),
        documentsForMerge,
      ),
    },
  });
}

export function* ensureChangeDocumentsType(documentsIds, type, resolve, reject) {
  try {
    const requestData = {
      documentID: documentsIds,
      document_type: type,
    };

    const { data } = yield call(Api.updateDocumentsTags, { data: requestData });

    executeFunc(resolve, data);

    return data;
  } catch (err) {
    const error = (err && err.response && err.response.data && err.response.data.error) || '';
    if (error) {
      yieldErrorNotification(error);
    }
    executeFunc(reject, err);
    throw err;
  }
}

export function* ensureGetGridViewDataCall({ params }: Params) {
  const query = params.query ? params.query : yield select(Router.querySelector);
  const { companyType, companyId } = params;
  const workspace = companyType ? { companyType } : {};
  const { category, category1 } = params;

  try {
    const { pageToken } = params;

    const { data } = yield call(Api.getGrid, {
      params: {
        category,
        companyId,
        pageToken,
        ...query,
        isConfidential: workspace && workspace.companyType === 'confidential',
      },
      paramsSerializer: (d) => queryToSearch(d),
    });

    if (!data?.dataSource?.length && category !== category1) {
      const route =
        workspace.companyType === 'confidential'
          ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
          : ROUTES_PATH.COMPANY_WORKSPACE.absolute;
      yield put(navigate.push(generatePath(route, { ...params })));
    }

    const gridDocumentsList = adapters.gridDocumentsListAdapter(data.dataSource);
    const companies = yield select(companiesByIdSelector);
    const format = companies.get(companyId).dateFormat || 'DD/MM/YYYY';
    const headers = data.headers.map((i) => ({ ...i, format }));
    const gridHeadersList = adapters.gridHeadersListAdapter(headers);

    yield put({
      type: actions.documentsGridViewGetAction.success,
      payload: {
        gridHeadersList,
        gridDocumentsList,
        timeStamp: data.timeStamp,
        count_without_link_panels: gridDocumentsList.size,
      },
    });
  } catch (err) {
    yield doLogout(actions.documentsGridViewGetAction, err);
  } finally {
    if (yield cancelled()) {
      yield put({
        type: actions.documentsGridViewGetAction.failure,
        error: 'canceled',
      });
    }
  }
}

export function* ensureGetGridViewData(params: Params) {
  yield race({ task: call(ensureGetGridViewDataCall, params), cancel: take(updateWorkSpaceAction.type) });
}

export function* ensureBulkChangeDocumentsType({ documentsIds, type, params, resolve, reject }) {
  try {
    const data = yield call(ensureChangeDocumentsType, documentsIds, type, resolve, reject);
    const { documents, ...stats } = data;
    const isGrid = yield select(isGridWorkSpaceSelector);

    yield call(actualizeLinkedDocuments, documents);
    if (isGrid) {
      yield call(actualizeDocuments, documents);
    }

    yield call(ensureGetGridViewDataOnlyGridView, params);

    executeFunc(resolve, stats);
  } catch (err) {
    yield doLogout(actions.documentsBulkUpdateAction, err);
  }
}

export function* ensureBulkApprove({ data, resolve, reject }) {
  try {
    const { data: response } = yield call(Api.bulkApprove, {
      data,
    });

    executeFunc(resolve, response);

    return response;
  } catch (err) {
    executeFunc(reject, err);
    yield doLogout(actions.documentsBulkApproveAction, err);
    throw err;
  }
}

export function* ensureBulkApproveOnWorkspace({ data, params, resolve, reject }) {
  try {
    const response = yield call(ensureBulkApprove, {
      data,
      resolve,
      reject,
    });
    const { documents } = response;
    const isGrid = yield select(isGridWorkSpaceSelector);

    yield call(actualizeLinkedDocuments, documents);
    if (isGrid) {
      yield call(actualizeDocuments, documents);
    }

    yield call(ensureGetGridViewDataOnlyGridView, params);

    executeFunc(resolve, response);
  } catch (err) {
    executeFunc(reject, err);
    yield doLogout(actions.documentsBulkApproveAction, err);
  }
}

export const ensureSetTypeDocument = (type) =>
  function* unnamed(documentID, params, resolve, reject) {
    try {
      const data = yield call(ensureChangeDocumentsType, [documentID], type, resolve, reject);
      const { documents } = data;

      if (documents[0]) {
        yield put({
          type: actions.documentUpdate.success,
          payload: documentAdapter(documents[0]),
        });
      }

      if (type === 'financial') {
        yield all([call(Journal.ensureTextMap, documentID), call(Journal.ensureJounalEntry, documentID)]);
      } else {
        yield call(Journal.ensureTextMap, documentID);
      }

      yield call(ensureGetGridViewDataOnlyGridView, params);

      if (typeof resolve === 'function') resolve();
    } catch (err) {
      if (typeof reject === 'function') reject(err);
      yield doLogout(actions.documentsChangeTypesAction, err);
    }
  };

export const ensureSetAsFinancialDocument = ensureSetTypeDocument('financial');

export const ensureSetAsGeneralDocument = ensureSetTypeDocument('general');

export function* ensureSignDocuments(documentsIds, signatureId, resolve, reject) {
  const requestData = {
    signatureID: signatureId,
    documentID: documentsIds,
  };

  try {
    const { data } = yield call(Api.signDocuments, { data: requestData });
    const { documents, ...stats } = data;

    yield call(actualizeLinkedDocuments, documents);

    executeFunc(resolve, stats);
  } catch (err) {
    executeFunc(reject, err);
    yield doLogout(actions.documentsBulkUpdateAction, err);
  }
}

export function* ensureBulkAccept({ documentsIds, params, resolve, reject }) {
  try {
    const { data } = yield call(Api.bulkAcceptDocuments, {
      data: {
        documentID: documentsIds,
      },
    });
    const { documents } = data;
    const isGrid = yield select(isGridWorkSpaceSelector);

    yield call(actualizeLinkedDocuments, documents);
    if (isGrid) {
      yield call(actualizeDocuments, documents);
    }

    yield call(ensureGetGridViewDataOnlyGridView, params);

    executeFunc(resolve, data);
  } catch (err) {
    executeFunc(reject, err);
    yield doLogout(actions.documentsBulkUpdateAction, err);
  }
}

export function* ensureMergeDocuments({ payload: { documentID, resolve = (x) => x, reject = (x) => x } }) {
  const requestData = { documentID };

  const isGrid = yield select(isGridWorkSpaceSelector);
  try {
    const { data } = yield call(Api.mergeDocuments, { data: requestData });
    yield put({
      type: actions.mergeDocumentsAction.success,
      payload: {
        isGrid,
        deletedDocumentIds: documentID,
      },
    });

    resolve(data);

    yieldSuccessNotification(
      <FormattedMessage
        values={{
          success: data.success.length,
          total: documentID.size,
        }}
        id="document.actions.merge"
        defaultMessage="{success} out of {total} documents were merged"
      />,
    );
  } catch (err) {
    reject(err);
    yield doLogout(actions.mergeDocumentsAction, err);
  }
}

export function* ensureMergeDocumentsForPrinting({ payload: { documentID, resolve = (x) => x, reject = (x) => x } }) {
  const requestData = { documentID };

  try {
    const response = yield call(Api.mergeDocumentsForPrinting, {
      data: requestData,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    });
    const pdfFile = new window.Blob([response.data], {
      type: 'application/pdf',
    });
    resolve(pdfFile);
  } catch (err) {
    const error = get(['response', 'data'])(err);
    if (error) {
      const msg = isArrayBuffer(error) ? decodeArrayBufferText(error) : error;
      yieldErrorNotification(
        typeof msg === 'string' ? (
          msg
        ) : (
          <FormattedMessage
            id="selectedPanel.toast.print.fetch.error"
            defaultMessage="We encountered a printing issue, please try again"
          />
        ),
      );
    }
    notify.captureEvent(err);
    reject(err);
    yield doLogout(actions.mergeDocumentsForPrintingAction, err);
  }
}

export function* ensureDownloadDocuments({ payload: { documentID, linkid } }) {
  try {
    const {
      data: { downloadUrl },
    } = yield call(Api.getDocumentsDownloadURL, { data: { documentID, linkID: linkid } });
    window.open(downloadUrl, '_blank');
  } catch (err) {
    yield doLogout(actions.documentsDownloadDocumentsAction, err);
  }
}

export function* ensureRemoveDocuments({ payload: { documentID, resolve, reject } }) {
  try {
    const { data } = yield call(Api.removeDocuments, {
      data: { documentID },
    });
    yield put({
      type: actions.documentsRemoveDocumentsAction.success,
      payload: { movedIDs: data.success, nonLinkedDocumentIDs: data.success },
    });
    resolve(data);
  } catch (err) {
    reject(err);
    yield doLogout(actions.documentsRemoveDocumentsAction, err);
  }
}

export function* ensureStopDocumentProcessing(documentID) {
  yield put({ type: actions.documentStopProcessingAction.request });
  try {
    const { data } = yield call(Api.stopDocumentProcessing, {
      data: { documentID },
    });
    yield put({
      type: actions.documentStopProcessingAction.success,
      payload: documentAdapter(data),
    });
  } catch (err) {
    yield doLogout(actions.documentStopProcessingAction, err);
  }
}

export function* ensureMoveDocumentToCategory({ payload, params }) {
  const {
    categoryId,
    documentID = [],
    linkedID = [],
    workspaceType,
    rootCategory: rootCategoryFromPayload,
    resolve = (x) => x,
    reject = (x) => x,
  } = payload;
  const rootCategories = yield select(rootCategoriesListSelector);
  const category = rootCategories.find((cat) => cat.id === categoryId);
  const isConfidential = workspaceType === 'confidential';

  const isMovingFromMorePopup = params && params.documentId;

  let rootCategory;
  if (!isMovingFromMorePopup) {
    rootCategory = rootCategoryFromPayload;
  } else {
    const backSearchUrl = yield select(backSearchUrlSelector);
    rootCategory = backSearchUrl && backSearchUrl.params.category1;
  }

  const isCurrentCategoryAll = isCategoryAll(findCatById(rootCategories, rootCategory));

  try {
    // move to category API call
    const { data } = yield call(Api.moveDocumentToCategory, {
      data: { documentID, linkedID, categoryId, isConfidential },
    });

    // update categories
    yield call(Categories.ensureGetCategories, {
      ...params,
      companyType: workspaceType,
    });

    if (!isCurrentCategoryAll) {
      // remove from current list
      yield put({
        type: actions.documentClear.type,
        payload: documentID,
      });
    }

    resolve(data);

    yieldSuccessNotification(
      <FormattedMessage
        values={{
          catName: (
            <span style={{ fontWeight: 600 }}>
              <FormattedMessage id={`category.name.${category.nameLangId}`} defaultMessage={category.name} />
            </span>
          ),
        }}
        id="category.move.toast.success"
        defaultMessage="Document was moved to {catName}"
      />,
    );

    if (!isMovingFromMorePopup) {
      const list = yield select(selector.documentsByIdSelector);
      const isGrid = yield select(isGridWorkSpaceSelector);
      if (isGrid) {
        yield call(ensureGetGridViewData, { params });
      } else {
        // request for all the documents that are currently in a list after unlinkin
        yield call(ensureSearchDocuments, { params, force: true }, list.size);
      }
    }
  } catch (err) {
    reject(err);
    yield doLogout(actions.documentStopProcessingAction, err);
  }
}

export function* ensureMoveAllCategoryDocumentsToOtherCategory({ payload }) {
  const { fromCategoryId, toCategoryId, workspaceType } = payload;
  const rootCategories = yield select(rootCategoriesListSelector);
  const category = rootCategories.find((cat) => cat.id === toCategoryId);
  const isConfidential = workspaceType === 'confidential';

  try {
    // move to category API call
    yield call(Api.moveAllCategoryDocumentsToOtherCategory, {
      params: {
        category: fromCategoryId,
        targetCategoryId: toCategoryId,
        isConfidential,
      },
    });

    // update categories
    yield call(Categories.ensureGetCategories, {
      companyType: workspaceType,
    });

    // remove from current list
    yield put({
      type: actions.documentClearAll.type,
      payload: null,
    });

    yieldSuccessNotification(
      <FormattedMessage
        values={{
          catName: (
            <span style={{ fontWeight: 600 }}>
              <FormattedMessage id={`category.name.${category.nameLangId}`} defaultMessage={category.name} />
            </span>
          ),
        }}
        id="category.move.toast.success"
        defaultMessage="Document was moved to {catName}"
      />,
    );
  } catch (err) {
    yield doLogout(actions.documentStopProcessingAction, err);
  }
}

export function* getViewArrangementStoreKey() {
  const userGUID = yield select(userGUIDSelector);
  const jeInvoiceType = yield select(jeInvoiceTypeSelector);
  return userGUID && jeInvoiceType ? [userGUID, jeInvoiceType].join('-') : null;
}

export function* ensureGetViewArrangement() {
  const storeKey = yield call(getViewArrangementStoreKey);
  if (storeKey) {
    try {
      const data = yield indexedDb.arrangementOptions.get(storeKey);
      if (data) {
        yield put({
          type: actions.documentGetViewArrangementAction.success,
          payload: data.view,
        });
      }
    } catch (err) {
      yield doLogout(actions.documentGetViewArrangementAction, err);
    }
  }
}

export function* ensureUpdateViewArrangement({ payload }) {
  const storeKey = yield call(getViewArrangementStoreKey);
  if (storeKey) {
    try {
      yield indexedDb.arrangementOptions.put({
        jeType: storeKey,
        view: payload,
      });
      yield put({
        type: actions.documentUpdateViewArrangementAction.success,
        payload,
      });
    } catch (err) {
      yield doLogout(actions.documentUpdateViewArrangementAction, err);
    }
  }
}

export function* ensureResetDocumentsList() {
  yield put({
    type: actions.documentClearAll.type,
    payload: null,
  });
}

export function* ensureGetEmailPayload({ payload: { documentID, resolve, reject } }) {
  try {
    const { data } = yield call(Api.getEmailData, {
      params: { documentID },
    });
    yield put({
      type: actions.getDocumentEmailPayloadAction.success,
      payload: data,
    });

    executeFunc(resolve, data);
  } catch (err) {
    executeFunc(reject, err);
    yield doLogout(actions.getDocumentEmailPayloadAction, err);
  }
}

export function* ensureGetHotkeyList() {
  try {
    yield put({
      type: actions.getDocumentHotkeysAction.request,
    });

    const { data } = yield call(Api.getDocumentHotkeys);
    yield put({
      type: actions.getDocumentHotkeysAction.success,
      payload: adapters.documentHotkeysAdapter(data),
      companyId: yield select(selector.currentCompanySelector),
    });
  } catch (err) {
    yield doLogout(actions.getDocumentHotkeysAction, err);
  }
}

function* _ensureSaveGridPreset(data) {
  const apiCall = data.id ? Api.updateWsGridPreset : Api.createWsGridPreset;
  const {
    data: { result, error },
  } = yield call(apiCall, { data });
  if (result) {
    yield put({
      type: actions.documentsGridSavePresetAction.success,
      payload: result,
    });
  } else if (error) {
    yieldErrorNotification(error);
  }
}

const extractNewPreset = (prev, current) => {
  const prevIds = prev.map((i) => i.id);
  return find(current, (i) => !prevIds.includes(i.id));
};

export function* ensureSetPreset(presetId) {
  yield put({
    type: actions.documentsGridSetCurrentPresetIdAction.type,
    payload: presetId,
  });

  yield put({
    type: actions.documentsGridClearConfigurationChangesAction.type,
    payload: null,
  });
}

export function* ensureSetNewPreset(prevPresets) {
  // if new preset exists set it as current preset
  const currentPresets = yield select(selector.gridRawPresetsSelector);
  const newPreset = extractNewPreset(prevPresets, currentPresets);
  if (newPreset) {
    yield call(ensureSetPreset, newPreset.id);
  }
}

export function* ensureSaveGridPreset({ payload: { name, isAll, id = null } }) {
  try {
    const prevPresets = yield select(selector.gridRawPresetsSelector);
    const {
      gridColumnsState: gridColumns,
      gridFilters,
      gridSorting,
    } = yield select(selector.gridCurrentSettingsStateSelector);

    const saveData = {
      id,
      name,
      all: isAll,
      config: { gridFilters, gridSorting, gridColumns },
    };
    yield call(_ensureSaveGridPreset, saveData);
    yield call(ensureSetNewPreset, prevPresets);
  } catch (err) {
    yield doLogout(actions.documentsGridSavePresetAction, err);
  }
}

export function* ensureDeleteGridPreset({ payload }) {
  const currentPresetId = yield select(selector.gridCurrentPresetIdSelector);
  try {
    const {
      data: { result, error },
    } = yield call(Api.deleteWsGridPreset, {
      data: {
        id: payload,
      },
    });
    if (result) {
      yield put({
        type: actions.documentsGridDeletePresetAction.success,
        payload: result,
      });
    } else if (error) {
      yieldErrorNotification(error);
    }

    // if delete current preset
    if (currentPresetId === payload) {
      yield call(ensureSetPreset, null);
    }
  } catch (err) {
    yield doLogout(actions.documentsGridDeletePresetAction, err);
  }
}

export function* ensureGetGridPresets() {
  try {
    const {
      data: { result, error },
    } = yield call(Api.getWsGridPresets, {
      params: {},
    });

    if (result) {
      yield put({
        type: actions.documentsGridGetPresetsAction.success,
        payload: result,
      });
    } else if (error) {
      yieldErrorNotification(error);
    }
  } catch (err) {
    yield doLogout(actions.documentsGridGetPresetsAction, err);
  }
}

export function* ensureGetSharesPreset({ payload }) {
  try {
    const {
      data: { result, error },
    } = yield call(Api.getWsGridPreset, {
      id: payload,
      params: {},
    });
    if (result) {
      yield put({
        type: actions.documentsGridGetSharedPresetAction.success,
        payload: result,
      });
      /* yield put({
        type: updateWorkSpaceAction.type,
        payload: { companyId, type: 'grid' },
      }); */
    } else if (error) {
      yieldErrorNotification(error);
    }
  } catch (err) {
    yield doLogout(actions.documentsGridGetSharedPresetAction, err);
  }
}

export function* ensureCheckResentSavedToIndexDbGridConfig() {
  const prevPresets = yield select(selector.gridRawPresetsSelector);
  const userId = yield select(userIdSelector);
  const companyId = yield select(selector.currentCompanySelector);
  const data = yield indexedDb.gridColumnsProps.get(userId);

  try {
    if (userId && companyId) {
      if (data && data[companyId]) {
        const saveData = {
          name: WS_GRID_RECENT_DEFAULT_NAME,
          all: false,
          config: { gridFilters: {}, gridSorting: [], gridColumns: data[companyId].columns },
        };

        yield call(_ensureSaveGridPreset, saveData);
        yield call(ensureSetNewPreset, prevPresets);
        indexedDb.gridColumnsProps.update(userId, { ...data, [companyId]: undefined });
      }
    }
  } catch (err) {
    if (err.response.status === 409) {
      indexedDb.gridColumnsProps.update(userId, { ...data, [companyId]: undefined });
    }
    yield doLogout(actions.documentsGridSavePresetAction, err);
  }
}

export function* ensureGetGridColumns() {
  const userId = yield select(userIdSelector);
  const companyId = yield select(selector.currentCompanySelector);

  if (userId && companyId) {
    const data = yield indexedDb.gridColumnsProps.get(userId);
    if (data && data[companyId]) {
      yield put({
        type: actions.documentsGridColumnsAppliedAction.type,
        payload: data[companyId].columns,
      });
    }
  }
}

export function* ensureSaveCurrentPresetId({ payload }) {
  const userId = yield select(userIdSelector);
  const companyId = yield select(selector.currentCompanySelector);
  if (userId && companyId) {
    const data = yield indexedDb.gridCurrentPresetIds.get(userId);
    const companies = (data && data.companies) || {};
    indexedDb.gridCurrentPresetIds.put({ userId, companies: { ...companies, [companyId]: payload } });
  }
}

export function* ensureLoadCurrentPresetId() {
  const userId = yield select(userIdSelector);
  const companyId = yield select(selector.currentCompanySelector);

  if (userId && companyId) {
    const data = yield indexedDb.gridCurrentPresetIds.get(userId);
    if (data && data.companies[companyId]) {
      yield put({
        type: actions.documentsGridRestoreResentPresetId.success,
        payload: data.companies[companyId],
      });
    }
  }
}

export function* ensureStoreGridFilterChanges({ payload: { filters } }) {
  const companyId = yield select(selector.currentCompanySelector);
  const currentPresetId = yield select(selector.gridCurrentPresetIdSelector);
  updateGridConfigChanges({ gridFilters: filters, companyId, currentPresetId });
}

export function* ensureStoreGridSortingChanges({ payload: { sorting } }) {
  const companyId = yield select(selector.currentCompanySelector);
  const currentPresetId = yield select(selector.gridCurrentPresetIdSelector);
  updateGridConfigChanges({ gridSorting: sorting, companyId, currentPresetId });
}

export function* ensureStoreGridColumnsChanges({ payload }) {
  const companyId = yield select(selector.currentCompanySelector);
  const currentPresetId = yield select(selector.gridCurrentPresetIdSelector);
  updateGridConfigChanges({ gridColumns: payload, companyId, currentPresetId });
}

export function ensureDeleteGridPresetChanges() {
  deleteGridConfigChanges();
}

export function* ensureRestoreGridPresetChanges() {
  const companyId = yield select(selector.currentCompanySelector);
  const currentPresetId = yield select(selector.gridCurrentPresetIdSelector);

  const currentStored = getCurrentStoredConfig();

  if (currentStored.companyId === companyId && currentStored.currentPresetId === currentPresetId) {
    // we changed the position of the context menu column, so we need to migrate with saved configs
    // we can remove it later
    if (requiresColumnMigration(currentStored.gridColumns)) {
      const gridColumns = changeColumnsPositionForGridRedesign(currentStored.gridColumns);
      updateGridConfigChanges({ gridColumns });

      yield put({
        type: actions.documentsGridColumnsAppliedAction.type,
        payload: gridColumns,
      });
    } else {
      yield put({
        type: actions.documentsGridColumnsAppliedAction.type,
        payload: currentStored.gridColumns,
      });
    }

    yield put({
      type: actions.documentsGridFilterAppliedAction.type,
      payload: { filters: currentStored.gridFilters },
    });

    yield put({
      type: actions.documentsGridSortingAppliedAction.type,
      payload: { sorting: currentStored.gridSorting },
    });
  }
}

export function* ensureIgnoreDocumentWarnings({ payload }) {
  const { resolve, reject, documentId, params } = payload;
  try {
    yield put({
      type: actions.documentIgnoreWarningsAction.request,
    });
    yield call(Api.ignoreDocumentWarnings, {
      data: { documentID: documentId, ignoreWarning: true },
    });
    yield put({
      type: actions.documentIgnoreWarningsAction.success,
      payload: documentId,
    });
    yieldInfoNotification(
      <FormattedMessage id="document.actions.ignoreWarnings.success" defaultMessage="Warnings ignored" />,
    );
    if (typeof resolve === 'function') resolve();
    yield call(ensureGetGridViewDataOnlyGridView, params);
  } catch (err) {
    if (typeof reject === 'function') reject(err);
    yield doLogout(actions.documentIgnoreWarningsAction, err);
  }
}

export function* ensureMoveDocumentsToCompany({ data, resolve, reject }) {
  try {
    const { toCompanyID, documentID, companyName, selectedDocumentsCount, linkID } = data;
    const docsData = linkID ? { linkID } : { documentID };
    const { data: response } = yield call(Api.moveDocumentsToCompany, {
      data: { toCompanyID, ...docsData },
    });

    // eslint-disable-next-line camelcase
    const { success, success_bundles, ignored, ignored_bundles } = response;
    const successIDs = success || [];
    // eslint-disable-next-line camelcase
    const successBundlesIDs = success_bundles || [];
    const ignoredIDs = ignored || [];
    // eslint-disable-next-line camelcase
    const ignoredBundlesIDs = ignored_bundles || [];
    const isMixedMove = ignoredBundlesIDs.length > 0 || successBundlesIDs.length > 0;

    // isMixedMove - available only from grid view
    if (isMixedMove) {
      const movedIDs = [...successIDs, ...successBundlesIDs.flat()];
      const documentsTotal = successIDs.length + ignoredIDs.length;
      const bundlesTotal = ignoredBundlesIDs.length + successBundlesIDs.length;
      const bundlesSuccessTotal = successBundlesIDs.length;

      yieldSuccessNotification(
        <Box>
          {documentsTotal > 0 && (
            <Box mb={1}>
              <FormattedMessage
                values={{
                  companyName,
                  success: successIDs.length,
                  total: documentsTotal,
                }}
                id="toast.moveMixedToCompany.documents"
                defaultMessage={`${successIDs.length} out of ${documentsTotal} documents were moved to the ${companyName}`}
              />
            </Box>
          )}
          <Box>
            <FormattedMessage
              values={{
                companyName,
                success: bundlesSuccessTotal,
                total: bundlesTotal,
              }}
              id="toast.moveMixedToCompany.bundles"
              defaultMessage={`${bundlesSuccessTotal} out of ${bundlesTotal} linked bundles were moved to the ${companyName}`}
            />
          </Box>
        </Box>,
      );
      // so in grid view count_without_link_panels - its mix ids from success and success_bundles
      yield put({
        type: actions.moveDocumentsToCompanyAction.success,
        payload: { movedIDs, nonLinkedDocumentIDs: movedIDs },
      });
    } else if (linkID) {
      yieldSuccessNotification(
        <FormattedMessage
          values={{
            companyName,
          }}
          id="toast.moveLinkedToCompany"
          defaultMessage={`Linked bundle moved to ${companyName}`}
        />,
      );
      yield put({
        type: actions.moveDocumentsToCompanyAction.success,
        payload: { movedIDs: successIDs, nonLinkedDocumentIDs: [] },
      });
    } else {
      yieldSuccessNotification(
        <FormattedMessage
          values={{
            success: successIDs.length,
            total: selectedDocumentsCount,
            companyName,
          }}
          id="toast.moveDocumentsToCompany"
          defaultMessage={`${successIDs.length} out of ${selectedDocumentsCount} documents moved to ${companyName}`}
        />,
      );

      yield put({
        type: actions.moveDocumentsToCompanyAction.success,
        payload: { movedIDs: successIDs, nonLinkedDocumentIDs: successIDs },
      });
    }

    executeFunc(resolve, response);
  } catch (err) {
    const error = (err && err.response && err.response.data && err.response.data.error) || '';
    if (error) {
      yieldErrorNotification(error);
    }
    executeFunc(reject, err);
    yield doLogout(actions.moveDocumentsToCompanyAction, err);
  }
}

export function* ensureMoveDocumentToCompany({ data, resolve, reject }) {
  try {
    const { toCompanyID, documentID, companyName } = data;
    const { data: response } = yield call(Api.moveDocumentToCompany, { data: { documentID, toCompanyID } });

    yieldSuccessNotification(
      <FormattedMessage
        id="toast.moveDocumentToCompany"
        defaultMessage="Document moved to {companyName}"
        values={{ companyName }}
      />,
    );

    yield put({
      type: actions.moveDocumentToCompanyAction.success,
      payload: {
        id: documentID,
      },
    });

    executeFunc(resolve, response);
  } catch (err) {
    const error = (err && err.response && err.response.data) || '';
    if (error) {
      yieldErrorNotification(error);
    }
    executeFunc(reject, err);
    yield doLogout(actions.moveDocumentToCompanyAction, err);
  }
}

export function* ensureHeaderTablesCorrection({ documentID, headerWords, MW }) {
  try {
    yield call(Api.headerTablesCorrection, {
      data: { table_header_hint: headerWords, documentID },
    });
    yield call(MW.init, ensureWorkerHeaderTablesCorrected, { documentID, MW, withoutCheck: true });
  } catch (err) {
    yield doLogout(actions.headerTablesCorrectionAction, err);
  }
}

export function* ensureUpdateDocumentAfterCorrectedTableHeader({ documentID, data }) {
  try {
    const res = yield call(ensureGetExtractedTableData, documentID);

    if (isEmpty(res.items_table)) {
      yieldErrorNotification(
        <FormattedMessage id="toast.notRecognizedTable" defaultMessage="The table was not recognized." />,
      );
    }

    yield put({ type: actions.documentUpdate.success, payload: documentAdapter(data) });
  } catch (err) {
    yield doLogout(actions.headerTablesCorrectionAction, err);
  }
}

export function* ensureWorkerHeaderTablesCorrected({ documentID, MW, withoutCheck = false }) {
  function* prepare() {
    return { documentID };
  }

  function* checkDisabled() {
    if (withoutCheck) return false;
    const shouldProcessingDocBeBlocked = yield select(selector.shouldProcessingDocBeBlockedSelector);
    return !shouldProcessingDocBeBlocked;
  }

  return {
    apiName: 'getDocument',
    config: {
      params: yield call(prepare),
    },
    prepare,
    disabled: yield call(checkDisabled),
    success(data) {
      if (data?.tags.length && !data.tags.includes(CONST.tags.isBeingProcessed)) {
        if (MW) {
          MW.terminate('getDocument');
        }
        return {
          type: actions.headerTablesCorrectionAction.success,
          payload: { data, documentID },
        };
      }
      return { type: actions.documentUpdate.success, payload: documentAdapter(data) };
    },
    failure(err) {
      return logoutAction(actions.documentGet, err);
    },
  };
}

export function* ensureSendDocumentByEmail({ payload }) {
  const { to, cc, subject, description, linkID, documentIds, emailsForLS, resolve, reject } = payload;
  try {
    const data = yield call(Api.sendDocByEmail, {
      data: { email_to: to, cc, subject, message: description, linkID, document_ids: documentIds },
    });

    if (emailsForLS.length) {
      storage().emailSuggestion().set(JSON.stringify(emailsForLS));
    }

    const recipients = [...to, ...(cc || [])].join(', ');

    yieldSuccessNotification(
      <FormattedMessage
        values={{
          emails: recipients,
        }}
        id="form.dialog.sendByEmail.toast.success"
        defaultMessage={`Document(s) sent to ${recipients} successfully`}
      />,
    );
    resolve(data);
  } catch (err) {
    yieldErrorNotification(err.response.data);
    reject(err);
    yield doLogout(actions.sendDocumentByEmailAction, err);
  }
}
